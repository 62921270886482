import React from 'react'
import { ModalRoutingContext } from 'gatsby-plugin-modal-routing-3'

import Product from '../components/Product'

const ProductPage = ({ data, nextPage, previousPage, pageContext, location }) => {
    return (
        <ModalRoutingContext.Consumer>
            {({ modal, closeTo }) => (
                <Product
                    {...data}
                    nextPage={nextPage}
                    previousPage={previousPage}
                    pageContext={pageContext}
                    location={location}
                    modal={modal}
                    closeTo={closeTo}
                />
            )}
        </ModalRoutingContext.Consumer>
    )
}

export default ProductPage
