import React from 'react'
import { graphql } from 'gatsby'

import Layout from '../../components/Layout'
import Seo from '../../components/Seo'
import ProductPage from '../ProductPage'

const product = ({ data, pageContext, location }) => {
    const { nextPage, previousPage, page } = data
    const { uri, featuredImage, seo } = page
    const { title } = seo
    const isModal = !!(location.state && location.state.modal)


    return (
        <Layout isFooterPromo={false} isHeader={!isModal} isFooter={!isModal}>
            <Seo title={title ? title : page.title} meta={seo} uri={uri} noIndex={true} />

            <ProductPage
                data={page}
                nextPage={nextPage}
                previousPage={previousPage}
                pageContext={pageContext}
                location={location}
            />
        </Layout>
    )
}

export const query = graphql`
    query product($id: String!, $nextPage: String, $previousPage: String) {
        page: wpProduct(id: { eq: $id }) {
            ...ProductContent
        }
        nextPage: wpProduct(id: { eq: $nextPage }) {
            title
            uri
        }
        previousPage: wpProduct(id: { eq: $previousPage }) {
            title
            uri
        }
    }
`

export default product
