import React from 'react'
import { Link } from 'gatsby'
import parse from 'html-react-parser'

import ProductGallery from './ProductGallery'
import ReviewsList from '../Reviews/ReviewsList'
import CloseIcon from '@assets/svg/close.inline.svg'
import ArrowRightIcon from '@assets/svg/arrow-right.inline.svg'
import ArrowLeftIcon from '@assets/svg/arrow-left.inline.svg'
import * as styles from './product.module.scss'

const Product = props => {
    const {
        title,
        modal,
        closeTo,
        nextPage,
        previousPage,
        product: { productPortion, productPrice, productDescription, productGallery, productPromo },
    } = props
    const { productPromoTitle, productPromoDescription, productReviews } = productPromo
    const currency = 'руб.';
    return (
        <section className={styles.product}>
            {modal && (
                <nav>
                    {closeTo && (
                        <Link
                            to={closeTo}
                            state={{
                                noScroll: true,
                            }}
                            className={styles.product__closeButton}
                        >
                            <CloseIcon />
                            Закрыть
                        </Link>
                    )}
                    {previousPage && (
                        <Link
                            to={previousPage.uri.replace('product', 'menu')}
                            state={{
                                modal: true,
                                noScroll: true,
                            }}
                            className={styles.product__prevButton}
                        >
                            <ArrowLeftIcon />
                            Предыдущая страница
                        </Link>
                    )}
                    {nextPage && (
                        <Link
                            to={nextPage.uri.replace('product', 'menu')}
                            state={{
                                modal: true,
                                noScroll: true,
                            }}
                            className={styles.product__nextButton}
                        >
                            <ArrowRightIcon />
                            Следующая страница
                        </Link>
                    )}
                </nav>
            )}
            <ProductGallery gallery={productGallery} />
            <div className={styles.product__inner}>
                <div>
                    <h1 className='global-title-h2'>{title}</h1>
                    {productDescription && <p className={styles.product__description}>{parse(productDescription)}</p>}
                    {productPortion && <p className={styles.product__description}>{parse(productPortion)}</p>}
                </div>
                <div>{productPrice && <p className={styles.product__price}>{parse(productPrice)} {currency}</p>}</div>
            </div>
            <div className={styles.product__promo}>
                {productPromoTitle && <h2 className='global-title-h3'>{parse(productPromoTitle)}</h2>}
                {productPromoDescription && (
                    <div className={styles.product__promoContent}>{parse(productPromoDescription)}</div>
                )}
                {productReviews && productReviews.length > 0 && <ReviewsList nodes={productReviews} />}
            </div>
        </section>
    )
}

export default Product
